import { cx } from "class-variance-authority"
import { ChevronRight } from "react-feather"
import { TruncatableText } from "shared-ui"

const CategoryMenuItem = ({
  handleOnClick,
  handleOnHover,
  isSelected = false,
  name,
}: {
  handleOnHover?: () => void
  handleOnClick?: () => void
  isSelected?: boolean
  name: string
}) => {
  return (
    <div
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnHover}
      onClick={handleOnClick}
      className={cx(
        isSelected ? "bg-secondary50 text-secondary500" : "text-tertiary500",
        "flex cursor-pointer items-center gap-3 self-stretch rounded-4 p-2 text-sm font-semibold"
      )}
    >
      <TruncatableText text={name} className="w-full" />
      <ChevronRight size={20} className="flex-shrink-0" />
    </div>
  )
}

export default CategoryMenuItem
