import { cx } from "class-variance-authority"
import Image from "next/image"
import { useRouter } from "next/navigation"
import { useEffect, useState } from "react"
import { ChevronDown } from "react-feather"
import { Dropdown, Spinner, TruncatableText } from "shared-ui"
import { isValidUrl } from "shared-utils"

import "./styles.css"

import CategoryMenuItem from "./components/CategoryMenuItem"
import CategoryTypeTabs from "./components/CategoryTypeTabs"

import { brokenImage } from "@/commons/images"
import {
  type MinifiedProductCategory,
  ProductType,
} from "@/federatedGql/graphql"
import useGetMinifedCategory from "@/hooks/product/useGetMinifedCategory"

const CategoryMenu = () => {
  const router = useRouter()
  const [activeTab, setActiveTab] = useState<ProductType>(ProductType.Physical)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] =
    useState<MinifiedProductCategory>()

  const { data, isFetching } = useGetMinifedCategory({
    enabled: !!activeTab && showDropdown,
    variables: {
      input: { level: 1, productType: activeTab },
    },
  })

  const toggleDropdown = () => setShowDropdown((prev) => !prev)

  const handleOnClick = (category: MinifiedProductCategory) => {
    router.push(`/search?categoryId=${category.id}`)
    toggleDropdown()
  }

  useEffect(() => {
    setSelectedCategory(data?.[0])
  }, [data])

  return (
    <Dropdown
      positionOffset={25}
      className="!border-t-0 !py-0 !shadow-none"
      variant="secondary"
      onOpenChange={toggleDropdown}
      open={showDropdown}
      trigger={
        <div className="inline-flex cursor-pointer items-center gap-1 text-tertiary500">
          <p className="text-base font-semibold">Kategori</p>
          <ChevronDown
            size={18}
            className={cx(showDropdown && "rotate-180 transition-all")}
          />
        </div>
      }
    >
      <div className="w-screen bg-primary25 px-10 text-tertiary500">
        <CategoryTypeTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        {isFetching ? (
          <div className="flex h-[500px] w-full items-center justify-center py-12">
            <Spinner size="medium" color="tertiary" />
          </div>
        ) : (
          <section className="flex h-[500px] gap-8 py-3">
            {/* Level 1 */}
            <div className="overflow-y-scroll md:w-1/4 lg:w-1/5">
              {data?.map((category) => (
                <CategoryMenuItem
                  key={category.id}
                  name={category.name}
                  isSelected={category.id === selectedCategory?.id}
                  handleOnHover={() => setSelectedCategory(category)}
                  handleOnClick={() => handleOnClick(category)}
                />
              ))}
            </div>

            {/* Level 2 */}
            <div className="w-full overflow-y-scroll">
              <span className="mb-5 flex w-full items-center gap-2">
                {selectedCategory?.icon && (
                  <Image
                    src={
                      isValidUrl(selectedCategory.icon)
                        ? selectedCategory.icon
                        : brokenImage
                    }
                    className="flex-shrink-0"
                    alt={selectedCategory.name ?? ""}
                    width={20}
                    height={20}
                  />
                )}
                {selectedCategory?.name && (
                  <TruncatableText
                    text={selectedCategory.name}
                    className="w-1/2 text-lg font-bold"
                  />
                )}
              </span>

              <div className="columns-4 gap-4">
                {selectedCategory?.children?.map((child) => (
                  <div className="mb-6 break-inside-avoid" key={child.id}>
                    {/* Level 2 with truncation */}
                    <button
                      id="category-menu-level-2"
                      type="button"
                      className="m-0 p-0 text-left"
                      onClick={() => handleOnClick(child)}
                    >
                      <TruncatableText
                        text={child.name}
                        className="mb-3 cursor-pointer text-base font-bold hover:text-secondary500"
                      />
                    </button>

                    {/* Level 3 */}
                    <ul className="flex flex-col gap-3">
                      {child.children?.map((grandChild) => (
                        <li key={grandChild.name}>
                          {/* Level 3 with truncation */}
                          <button
                            id="category-menu-level-3"
                            type="button"
                            className="m-0 p-0 text-left"
                            onClick={() => handleOnClick(grandChild)}
                          >
                            <TruncatableText
                              text={grandChild.name}
                              className="cursor-pointer text-xs hover:text-secondary500"
                            />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
      {/* Overlay */}
      <div
        onMouseEnter={toggleDropdown}
        className="absolute h-screen w-screen bg-tertiary500 bg-opacity-50"
      ></div>
    </Dropdown>
  )
}

export default CategoryMenu
